import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import md5 from "md5";

import { Form } from "@unform/web";

import Input from "../components/Input";
import { useRecoilState } from "recoil";
import { usuarioState } from "../atoms/usuarioState";
import { Usuario } from "../interfaces";

function FichasList() {
  const history = useHistory();
  const [usuario, setUsuario] = useRecoilState(usuarioState);
  const [showMsg, setShowMsg] = useState(false);
  const formRef = useRef(null);

  async function onSubmit(data: Usuario) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required("O email é obrigatório"),
        senha: Yup.string().required("A senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const ref = formRef.current as any;
      const { email, senha } = ref.getData();

      const u = await axios(
        "http://ficha.eficaztech.com:8001/usuario/" + email
      );

      if (u.data) {
        if (md5(senha) === u.data.senha) {
          setUsuario(u.data);
          history.push("/list");
          return;
        }
      }

      ref.reset();
      ref.setErrors({});
      setShowMsg(true);

      const timer = setTimeout(() => {
        setShowMsg(false);
        clearTimeout(timer);
      }, 2000);
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMsgs: any = {};
        e.inner.forEach((err) => {
          errorMsgs[err.path] = err.message;
        });
        const ref = formRef.current as any;
        ref.setErrors(errorMsgs);
      }
    }
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialData={usuario} ref={formRef}>
        <div className="px-32 mx-64">
          <h1 className="text-xl font-semibold border-b-2 mb-12">Login</h1>
          <div>
            {showMsg ? (
              <div className="text-center py-6 mb-4 items-center">
                <span className="bg-red-300 text-black px-32 py-6">
                  Usuário ou senha inválidos
                </span>
              </div>
            ) : null}
            <div>
              <label className="pr-2">Email</label>
              <br />
              <Input name="email" />
            </div>
            <div className="mt-4">
              <label className="pr-2">Senha</label>
              <br />
              <Input name="senha" type="password" autoComplete="false" />
            </div>
            <div className="mt-8 mb-8 text-center">
              <button className="px-4 border p-4 w-40 bg-blue-800 hover:bg-blue-700 text-white">
                Entrar
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default FichasList;
