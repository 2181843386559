import { atom } from 'recoil'
import { Ficha } from '../interfaces'

const fichas: Ficha[] = []

const fichasState = atom({
  key: 'fichasState',
  default: fichas,
})

export { fichasState }
