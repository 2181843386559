import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Font,
} from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import axios from "axios";

const FichaPdf = () => {
  Font.register({ family: "Courier", fonts: [] });

  const location = useLocation();

  const [ficha, setFicha] = useState(null);

  const id = location.pathname.split("/")[2];

  async function load(id: string) {
    const resp = await axios("http://ficha.eficaztech.com:8001/ficha/" + id);
    setFicha(resp.data);
  }

  useEffect(() => {
    load(id);
  }, [id]);

  const f: any = ficha;

  return f ? (
    <div className="h-screen">
      <PDFViewer className="h-screen w-full">
        <Document>
          <Page size="A4" style={{ paddingLeft: "65mm", paddingTop: "190mm" }}>
            <View>
              <View
                style={{
                  //fontFamily: "Courier",
                  width: "125mm",
                  height: "75mm",
                  padding: 12,
                  border: 1,
                  textAlign: "justify",
                }}
              >
                <Text style={{ fontSize: 10, textIndent: 20, marginBottom: 6 }}>
                  {f.sobrenomeAutor}, {f.nomeAutor}
                </Text>
                <Text style={{ fontSize: 10, textIndent: 20, marginBottom: 6 }}>
                  <Text style={{ color: "white", textIndent: 20 }}>
                    {f.sobrenomeAutor.substring(0, 2)}
                  </Text>
                  {f.titulo.trim()}
                  {f.subtitulo ? ": " + f.subtitulo.trim() : ""}/
                  {f.nomeAutor.trim()} {f.sobrenomeAutor.trim()}.&#8212;
                  {f.local.trim()}, {f.ano}.
                </Text>
                <Text style={{ fontSize: 10, textIndent: 20, marginBottom: 6 }}>
                  <Text style={{ color: "white", textIndent: 20 }}>
                    {f.sobrenomeAutor.substring(0, 2)}
                  </Text>
                  {f.numeroPaginasFolhas}{" "}
                  {f.paginasFolhas === "Páginas" ? "p" : "f"}.
                </Text>
                {f.nomeCoorientador ? (
                  <Text
                    style={{ fontSize: 10, textIndent: 20, marginBottom: 6 }}
                  >
                    <Text style={{ color: "white", textIndent: 20 }}>
                      {f.sobrenomeAutor.substring(0, 2)}
                    </Text>
                    Coorientador:{" "}
                    {f.titulacaoCoorientador
                      ? f.titulacaoCoorientador.trim() + " "
                      : ""}
                    {f.nomeCoorientador} {f.sobrenomeCoorientador}.
                  </Text>
                ) : null}
                <Text style={{ fontSize: 10, textIndent: 20, marginBottom: 6 }}>
                  <Text style={{ color: "white", textIndent: 20 }}>
                    {f.sobrenomeAutor.substring(0, 2)}
                  </Text>
                  Orientador:{" "}
                  {f.titulacaoOrientador ? f.titulacaoOrientador.trim() : ""}{" "}
                  {f.nomeOrientador.trim()} {f.sobrenomeOrientador.trim()}.
                </Text>
                <Text
                  style={{ fontSize: 10, textIndent: 20, marginBottom: 12 }}
                >
                  <Text style={{ color: "white", textIndent: 20 }}>
                    {f.sobrenomeAutor.substring(0, 2)}
                  </Text>
                  {f.naturezaTrabalho} - Curso de {f.curso.trim()}, Institutos
                  de Estudos Superiores do Maranhão, {f.local.trim()}, {f.ano}.
                </Text>
                <Text
                  style={{ fontSize: 10, textIndent: 20, marginBottom: 12 }}
                >
                  <Text style={{ color: "white", textIndent: 20 }}>
                    {f.sobrenomeAutor.substring(0, 2)}
                  </Text>
                  1.{f.palavraChave1.trim()}. 2.{f.palavraChave2.trim()}. 3.
                  {f.palavraChave3.trim()}.
                  {f.palavraChave4 ? " 4." + f.palavraChave4.trim() + "." : ""}
                  {f.palavraChave5
                    ? " 5." + f.palavraChave5.trim() + "."
                    : ""}{" "}
                  I.Título.
                </Text>
                <View style={{ flex: 1 }}></View>
                <Text style={{ fontSize: 10, textAlign: "right" }}>
                  CDU {f.cdu}
                </Text>
              </View>
              <Text style={{ fontSize: 10, textIndent: 40 }}>
                Elaborada por Lúcia Cristina Lopes Pestana - CRB 414
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  ) : null;
};

export default FichaPdf;
