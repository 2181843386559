import React from 'react'

import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import LoginForm from './pages/LoginForm'
import FichasList from './pages/FichasList'
import FichaForm from './pages/FichaForm'
import FichaPdf from './pages/FichaPdf'
import Header from './components/Header'

function App() {
  return (
    <RecoilRoot>
      <div className='App'>
        <Router>
          <Header />
          <div className='container mx-auto mt-8'>
            <Switch>
              <Route exact path='/'>
                <LoginForm />
              </Route>
              <Route exact path='/list'>
                <FichasList />
              </Route>
              <Route path='/form'>
                <FichaForm />
              </Route>
              <Route path='/pdf'>
                <FichaPdf />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </RecoilRoot>
  )
}

export default App
