import React, { useEffect, useCallback, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { useRecoilState } from "recoil";
import axios from "axios";

import { fichasState } from "../atoms/fichasState";
import { Ficha } from "../interfaces";
import { usuarioState } from "../atoms/usuarioState";
import { BsSearch } from "react-icons/bs";
import { fichaState } from "../atoms/fichaState";

function FichasList() {
  const history = useHistory();

  const [filtro, setFiltro] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [fichas, setFichas] = useRecoilState(fichasState);
  const [usuario] = useRecoilState(usuarioState);
  const [, setFicha] = useRecoilState(fichaState);

  const admin = usuario && usuario.email === "luciacristina@iesma.edu.br";

  const load = useCallback(async () => {
    try {
      if (!usuario) {
        history.push("/");
      }
      setIsLoading(true);
      if (admin) {
        const resp = await axios.get("http://ficha.eficaztech.com:8001/fichas");
        const fs = resp.data;
        const fs1 = [...fs];
        const f1 = fs1.filter((f: Ficha) => f.cdu.trim() === "");
        const f2 = fs1.filter((f: Ficha) => f.cdu.trim() !== "");

        f1.sort(
          (a: Ficha, b: Ficha) =>
            (a.titulo > b.titulo ? 1 : -1) || (a.cdu > b.cdu ? 1 : -1)
        );
        f2.sort(
          (a: Ficha, b: Ficha) =>
            (a.titulo > b.titulo ? 1 : -1) || (a.cdu > b.cdu ? 1 : -1)
        );

        // mostrar primeiro os sem cdus e depois os com cdus
        const fs2 = [...f1, ...f2];
        setFichas(fs2);
      } else {
        const resp = await axios.get(
          "http://ficha.eficaztech.com:8001/fichas/" + usuario.email
        );
        const fs = resp.data;
        const fs1 = [...fs];
        const f1 = fs1.filter((f: Ficha) => f.cdu.trim() === "");
        const f2 = fs1.filter((f: Ficha) => f.cdu.trim() !== "");

        f1.sort(
          (a: Ficha, b: Ficha) =>
            (a.titulo > b.titulo ? 1 : -1) || (a.cdu > b.cdu ? 1 : -1)
        );
        f2.sort(
          (a: Ficha, b: Ficha) =>
            (a.titulo > b.titulo ? 1 : -1) || (a.cdu > b.cdu ? 1 : -1)
        );

        // mostrar primeiro os sem cdus e depois os com cdus
        const fs2 = [...f1, ...f2];
        setFichas(fs2);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, [usuario, admin, history, setFichas]);

  function novaFicha() {
    setFicha(null);
    history.push("/form");
  }

  function atualizarLista() {
    load();
  }

  useEffect(() => {
    load();
  }, [load]);

  async function pesquisar() {
    const resp = await axios.post(
      "http://ficha.eficaztech.com:8001/fichas/search",
      {
        email: usuario.email,
        filtro: filtro,
      }
    );
    setFichas(resp.data);
  }

  return (
    <div>
      <div className="flex w-full align-middle h-10 mb-4">
        <div className="flex-grow text-lg font-semibold self-center">
          Fichas
        </div>
        {admin ? (
          <div className="flex flex-row items-center self-center">
            <input
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  pesquisar();
                }
              }}
              className="h-10 border p-2"
            />
            <button
              onClick={pesquisar}
              className="border mr-2 hover:bg-gray-300 hover:cursor-pointer"
            >
              <BsSearch className="mx-4 h-10" />
            </button>
          </div>
        ) : null}
        <button
          onClick={novaFicha}
          className="h-10 w-40 border float-right bg-blue-500 hover:bg-blue-300 text-white"
        >
          Criar Ficha
        </button>
        <button
          onClick={atualizarLista}
          className="h-10 w-40 border float-right bg-green-500 hover:bg-blue-300 text-white"
        >
          Atualizar Lista
        </button>
      </div>
      {isLoading ? (
        <div className="flex w-full justify-center">
          <img src="/load.gif" alt="loading" className="w-16" />
        </div>
      ) : null}
      <table className="table-auto w-full">
        <thead>
          <tr className="font-semibold">
            <td className="py-2 px-4">Autor</td>
            <td className="py-2 px-4">Título</td>
            <td className="py-2 px-4">CDU</td>
            <td className="w-12"></td>
            <td className="w-12"></td>
          </tr>
        </thead>
        <tbody>
          {fichas.map((ficha) => (
            <FichaTD
              key={ficha.id}
              ficha={ficha}
              onEditar={() => {
                setFicha(ficha);
                history.push("/form");
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function FichaTD({ ficha, onEditar }: { ficha: Ficha; onEditar: Function }) {
  return (
    <tr className={ficha.cdu ? "bg-gray-100" : ""}>
      <td className="py-2 px-4 border">
        {ficha.nomeAutor} {ficha.sobrenomeAutor}
      </td>
      <td className="py-2 px-4 border">{ficha.titulo}</td>
      <td className="py-2 px-4 border">{ficha.cdu}</td>
      <td className="border">
        <button
          className="py-2 px-4 h-full w-full bg-blue-500 hover:bg-blue-300 text-white"
          onClick={() => onEditar()}
        >
          Editar
        </button>
      </td>
      <td className="border">
        <Link
          to={"/pdf/" + ficha.id}
          target="_blank"
          className="py-2 px-4 h-full w-full bg-teal-500 hover:bg-blue-300 text-white"
        >
          Imprimir
        </Link>
      </td>
    </tr>
  );
}

export default FichasList;
