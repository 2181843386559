import { atom } from 'recoil'
import { Ficha } from '../interfaces'

const ficha: Ficha | null = null

const fichaState = atom({
  key: 'fichaState',
  default: ficha,
})

export { fichaState }
