import React, { useRef, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";

import { Form } from "@unform/web";

import Input from "../components/Input";
import Select from "../components/Select";
import { Ficha } from "../interfaces";
import { useRecoilState } from "recoil";
import { usuarioState } from "../atoms/usuarioState";
import { fichaState } from "../atoms/fichaState";

function LoginForm() {
  const history = useHistory();

  const [usuario] = useRecoilState(usuarioState);
  const [ficha, setFicha] = useRecoilState(fichaState);

  const [showMsg, setShowMsg] = useState(false);
  const formRef = useRef(null);

  const admin = usuario && usuario.email === "luciacristina@iesma.edu.br";

  useEffect(() => {
    const ref = formRef.current as any;
    if (ficha) {
      ref.setData(ficha);
    } else {
      const ficha = {
        email: usuario.email,
      };
      ref.setData(ficha);
    }
  }, [ficha, usuario.email]);

  function voltar() {
    setFicha(null);
    history.push("/list");
  }

  async function excluir(id: string) {
    await axios.delete("http://ficha.eficaztech.com:8001/ficha/" + id);
    setFicha(null);
    history.push("/list");
  }

  const naturezas = [
    "Dissertação",
    "Monografia (Graduação)",
    "Monografia - Especialização",
    "Tese",
  ];

  async function sendEmailToLucia(email: string) {
    await axios.post("http://ficha.eficaztech.com:8001/email", {
      to: "luciacristina@iesma.edu.br",
      text:
        "Lúcia, uma Ficha Catalográfica foi cadastrada/alterada pelo usuário " +
        email,
    });
  }

  async function sendEmailToUsuario(email: string) {
    await axios.post("http://ficha.eficaztech.com:8001/email", {
      to: email,
      text: "Caro usuário, O CDU de sua Ficha Catalográfica foi atribuído.",
    });
  }

  async function onSubmit(data: Ficha) {
    try {
      const schema = Yup.object().shape({
        titulo: Yup.string().required("O título é obrigatório"),
        nomeAutor: Yup.string().required("O nome do autor é obrigatório"),
        sobrenomeAutor: Yup.string().required(
          "O sobrenome do autor é obrigatório"
        ),
        titulacaoOrientador: Yup.string().required(
          "A titulação do orientador é obrigatória"
        ),
        nomeOrientador: Yup.string().required(
          "O nome do orientador é obrigatório"
        ),
        sobrenomeOrientador: Yup.string().required(
          "O sobrenome do orientador é obrigatório"
        ),
        ano: Yup.string().required("O ano é obrigatório"),
        curso: Yup.string().required("O curso é obrigatório"),
        local: Yup.string().required("O local é obrigatório"),
        naturezaTrabalho: Yup.string().required(
          "A natureza do trabalho é obrigatória"
        ),
        paginasFolhas: Yup.string().required(
          "Você deve dizer se a quantidade é em páginas ou em folhas"
        ),
        numeroPaginasFolhas: Yup.string().required("O número é obrigatório"),
        palavraChave1: Yup.string().required("A palavra-chave é obrigatória"),
        palavraChave2: Yup.string().required("A palavra-chave é obrigatória"),
        palavraChave3: Yup.string().required("A palavra-chave é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const ref = formRef.current as any;

      const dataret: any = await axios.post(
        "http://ficha.eficaztech.com:8001/ficha",
        data
      );
      if (dataret && dataret.data && dataret.data[0]) {
        const idRet = dataret.data[0];
        //ref.setFieldValue('id', idRet)
        setFicha({ ...ficha, id: idRet });
      }
      setShowMsg(true);

      if (admin && ref.getFieldValue("cdu")) {
        // se eh admin e tem cdu
        // envia email para o usuario
        sendEmailToUsuario(ref.getFieldValue("email"));
      }
      if (!admin && !ref.getFieldValue("cdu")) {
        // se nao eh admin e nao tem cdu
        // envia email para lúcia
        sendEmailToLucia(ref.getFieldValue("email"));
      }

      const timer = setTimeout(() => {
        setShowMsg(false);
        clearTimeout(timer);
      }, 2000);

      ref.setErrors({});
    } catch (e) {
      if (e instanceof Yup.ValidationError) {
        const errorMsgs: any = {};
        e.inner.forEach((err) => {
          errorMsgs[err.path] = err.message;
        });
        const ref = formRef.current as any;
        ref.setErrors(errorMsgs);
      }
    }
  }

  return (
    <div>
      <h1 className="text-xl font-semibold border-b-2 mb-4">Dados da Obra</h1>

      <Form onSubmit={onSubmit} ref={formRef}>
        <Input name="id" hidden />
        <Input name="email" hidden />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="pr-2">Título</label>
            <br />
            <Input name="titulo" maxLength="200" />
          </div>
          <div>
            <label className="pr-2">Subtítulo</label>
            <br />
            <Input name="subtitulo" maxLength="200" />
          </div>
          <div>
            <label className="pr-2">Nome do Autor</label>
            <br />
            <Input name="nomeAutor" maxLength="100" />
          </div>
          <div>
            <label className="pr-2">Sobrenome do Autor</label>
            <br />
            <Input name="sobrenomeAutor" maxLength="100" />
          </div>
          <div>
            <label className="pr-2">Titulação do Orientador</label>
            <br />
            <Input
              name="titulacaoOrientador"
              maxLength="30"
              placeholder="Ex: Prof. Dr."
            />
          </div>
          <div>
            <label className="pr-2">Titulação do Coorientador</label>
            <br />
            <Input
              name="titulacaoCoorientador"
              maxLength="30"
              placeholder="Ex: Profa. Dra."
            />
          </div>
          <div>
            <label className="pr-2">Nome do Orientador</label>
            <br />
            <Input name="nomeOrientador" maxLength="100" />
          </div>
          <div>
            <label className="pr-2">Sobrenome do Orientador</label>
            <br />
            <Input name="sobrenomeOrientador" maxLength="100" />
          </div>
          <div>
            <label className="pr-2">Nome do Coorientador</label>
            <Input name="nomeCoorientador" maxLength="100" />
          </div>
          <div>
            <label className="pr-2">Sobrenome do Coorientador</label>
            <br />
            <Input name="sobrenomeCoorientador" maxLength="100" />
          </div>
        </div>
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="pr-2">Ano</label>
              <Input name="ano" maxLength="4" />
            </div>
            <div>
              <label className="pr-2">Curso</label>
              <Input name="curso" maxLength="50" />
            </div>
            <div>
              <label className="pr-2">CDU</label>
              {admin ? (
                <Input name="cdu" maxLength="50" />
              ) : (
                <Input
                  name="cdu"
                  maxLength="50"
                  className="w-full bg-red-100 border p-2"
                  readOnly={true}
                  disabled={true}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div>
              <label className="pr-2">Local</label>
              <Input name="local" maxLength="100" />
            </div>
            <div>
              <label className="pr-2">Natureza do Trabalho</label>
              <br />
              <Select
                name="naturezaTrabalho"
                maxLength="200"
                options={naturezas}
              />
            </div>
            <div style={{ display: "none" }}>
              <label className="pr-2">Páginas ou Folhas?</label>
              <br />
              <Select
                name="paginasFolhas"
                options={["Folhas", "Páginas"]}
                value="Folhas"
              />
            </div>
            <div>
              <label className="pr-2">Número de Folhas</label>
              <br />
              <Input name="numeroPaginasFolhas" maxLength="5" />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label className="pr-2">Palavra-chaves</label>
          <br />
          <div className="grid grid-cols-5 gap-2">
            <Input name="palavraChave1" maxLength="50" />
            <Input name="palavraChave2" maxLength="50" />
            <Input name="palavraChave3" maxLength="50" />
            <Input name="palavraChave4" maxLength="50" />
            <Input name="palavraChave5" maxLength="50" />
          </div>
        </div>
        <div className="mt-4 mb-8 flex items-center">
          <div>
            <button className="px-4 border p-4 w-80 bg-blue-800 hover:bg-blue-700 text-white">
              Salvar e encaminhar ficha
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => voltar()}
              className="px-4 border p-4 w-80 bg-blue-800 hover:bg-blue-700 text-white"
            >
              Voltar para tela anterior
            </button>
          </div>
          <div>
            <Link
              to={"/pdf/" + (ficha ? ficha.id : 0)}
              target="_blank"
              className="px-4 text-center float-right border p-4 w-40 bg-teal-500 hover:bg-teal-400 text-white"
            >
              Imprimir
            </Link>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                if (window.confirm("Deseja realmente excluir esta ficha?")) {
                  let fid = ficha ? ficha.id : "0";
                  excluir(fid);
                }
              }}
              className="px-4 border p-4 w-80 bg-red-800 hover:bg-red-700 text-white"
            >
              Excluir
            </button>
          </div>
          <div>
            {showMsg ? (
              <span className="animate-pulse bg-green-200 py-4 px-4 mx-4">
                Ficha salva e encaminhada para a bibliotecária analisar.
              </span>
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
}

export default LoginForm;
