import { atom } from 'recoil'
import { Usuario } from '../interfaces'

const usuario: Usuario | null = null

const usuarioState = atom({
  key: 'usuarioState',
  default: usuario,
})

export { usuarioState }
