import React from 'react'
import { useRecoilState } from 'recoil'
import { usuarioState } from '../atoms/usuarioState'
import { useHistory } from 'react-router-dom'
import { fichasState } from '../atoms/fichasState'

export default function Header() {
  const [usuario, setUsuario] = useRecoilState(usuarioState)
  const [, setFichas] = useRecoilState(fichasState)

  const history = useHistory()

  function logout() {
    setUsuario(null)
    setFichas([])
    history.push('/')
  }

  return (
    <div className='flex-row text-lg bg-blue-500 text-white py-4 px-10'>
      <span className='flex-1 text-lg font-semibold text-white'>
        Ficha Catalográfica
      </span>
      {usuario ? (
        <span className='flex-1 float-right'>
          {usuario.email}
          <button className='ml-2' onClick={logout}>
            (sair)
          </button>
        </span>
      ) : null}
    </div>
  )
}
