import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'

export default function Input({ name, options, ...rest }) {
  const selectRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <select
      ref={selectRef}
      defaultValue={defaultValue}
      className='p-2 w-full bg-blue-100 border'
    >
      {options.map(opt => (
        <option key={opt}>{opt}</option>
      ))}
    </select>
  )
}
